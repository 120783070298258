<template>
  <!-- 左侧菜单 -->
  <div class="left-menu">
    <div class="left-menu-li">
      <div v-for="(item, index) in routerPath.children" :key="index">
        <div class="left-menu-title">{{ item.meta.title }}</div>
        <div class="left-menu-list" v-for="(items, indexs) in item.children" :key="indexs">
          <router-link
            v-if="!items.hidden"
            :class="['left-menu-item', index == indexActive[0] && indexs == indexActive[1] ? 'menu-active' : '',]"
            :to="{
              path: '/' + routerPath.path + '/' + item.path + '/' + items.path,
            }"
          >
            {{ items.meta.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["indexActive", "routerPath"]),
  },
};
</script>

<style scoped>
.left-menu {
  background-color: #fff;
  height: 100%;
  box-shadow: 0px 3px 6px #f4f6f9;
  padding: 20px 0px;
  box-sizing: border-box;
  overflow: auto;
}
.left-menu-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #000000;
  padding: 10px 20px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.left-menu-list {
  display: flex;
  flex-direction: column;
}
.left-menu-item {
  font-size: 14px;
  font-weight: normal;
  color: #707070;
  padding: 6px 25px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-left: 6px solid transparent;
  transition: all 0.2s;
}
.left-menu-item:hover {
  color: #1f5bcc;
}
.menu-active {
  color: #1f5bcc;
  border-left: 6px solid #1f5bcc;
}
</style>