<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="login-form">
      <div class="login-header">
        <p style="font-size:28px;font-weight:600;">摩尔创新管理后台</p>
      </div>
      <div class="login-content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="auto"
          class="login-ruleForm"
        >
          <el-form-item prop="username">
            <el-input v-model="ruleForm.username">
              <div slot="prepend" class="input-slot">
                <i class="el-icon-user-solid"></i> 账号
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="ruleForm.password">
              <div slot="prepend" class="input-slot">
                <i class="el-icon-s-goods"></i> 密码
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="captcha">
            <div class="code">
              <el-input
                v-model="ruleForm.captcha"
                placeholder="请输入验证码"
              ></el-input>
              <div class="code-img" @click="getCaptcha">
                <img :src="yzmUrl" alt="" />
              </div>
            </div>
          </el-form-item>
        </el-form>
        <el-button
          :style="{ color: buttonTextColor }"
          type="primary"
          @click="submitForm"
          class="submit"
          >登录</el-button
        >
        <el-button type="text" @click="forgetPasswd">忘记密码</el-button>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        captcha: null, //验证码
        checkKey: null, // 验证码key
        password: null, // 密码
        username: null, // 账号
      },
      yzmUrl: "",
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 2,
            max: 64,
            message: "长度在 2 到 64 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
        captcha: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  components: {},
  created() {
    this.getCaptcha();
  },
  computed: {
    ...mapState(["buttonTextColor", "ORIGINAL_THEME"]),
  },

  methods: {
    ...mapMutations(["setNewRouter", "clearNewRouter"]),
    // 登录
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.request
            .login(this.ruleForm)
            .then((res) => {
              if (res.code==0) {
                if (res.data!=null&&res.data.userInfo!=null) {
                  localStorage.setItem("userInfo",JSON.stringify(res.data.userInfo)); //用户信息
                }
                localStorage.setItem("token", res.data.token); //token
                localStorage.setItem("menuTree",JSON.stringify(res.data.menuTree)); //页面权限
                localStorage.setItem("buttonPerms",JSON.stringify(res.data.buttonPerms)); //按钮权限
                this.clearNewRouter();
                this.setNewRouter({
                  routerArr: res.data.menuTree,
                });
                setTimeout(() => {
                  this.$router.push({ path: "/" + res.data.menuTree[0].path });
                }, 1000);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("login form valid false");
          return false;
        }
      });
    },
    // 获取验证码
    getCaptcha() {
      this.ruleForm.checkKey = new Date().getTime();
      this.request.randomImage({ key: this.ruleForm.checkKey }).then((res) => {
        this.yzmUrl = res.data;
      });
    },
    //忘记密码
    forgetPasswd() {
      this.$router.push({ path: "/forgetPassword" });
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
}
.login-bg {
  width: 60%;
  background-image: url("../../assets/images/login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
}
.login-header img {
  margin-bottom: 20px;
  background: #409EFF;
  border-radius: 5px;
  padding: 10px;
}
.login-header h2 {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
}
.login-footer {
  margin-bottom: 40px;
  text-align: center;
  font-size: 14px;
  color: #9a9995;
}
.login-content {
  flex: 1;
  padding: 0 18%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-ruleForm {
  width: 100%;
}
.input-slot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-slot i {
  margin-right: 6px;
}
.code {
  display: flex;
}
.code-img {
  width: 30%;
  flex-shrink: 0;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
.code-img img {
  width: 100%;
  display: block;
}
.submit {
  width: 100%;
  margin-bottom: 80px;
}
</style>