<template>
    <div class="dilu-page" style="background-color: white;">

        <!-- 汇总信息数据 -->
        <DiluTable :list="summaryData" :tableConfig="summaryConfig" class="summary" style="margin-bottom:1px;">
            <el-table-column prop="tenantTotal" label="平台租户数" align="center"/>
            <el-table-column prop="connectTotal" label="接入设备数" align="center"/>
            <el-table-column prop="projectTotal" label="总计项目数" align="center"/>
            <el-table-column prop="userTotal" label="总计用户数" align="center"/>
            <el-table-column prop="gatewayTotal" label="总计网关数" align="center"/>
            <el-table-column prop="logTotal" label="API访问次数" align="center"/>
            <el-table-column prop="updateTime" label="数据更新日期" align="center"/>
        </DiluTable>

        <div>

            <!--通讯网关活跃数-->
            <div class="module-header">
                <div class="title">通讯网关活跃数</div>
                <div class="header-region">
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype1 == 10">最近30天</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype1 == 20">最近12月</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype1 == 30">最近10年</span>
                    <el-dropdown @command="staticGateway">
                        <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command=10>最近30天</el-dropdown-item>
                            <el-dropdown-item command=20>最近12月</el-dropdown-item>
                            <el-dropdown-item command=30>最近10年</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="add-content">
                <div id="gateway-echarts"/>
            </div>

            <!--APP用户活跃数-->
            <div class="module-header">
                <div class="title">APP用户活跃数</div>
                <div class="header-region">
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype2 == 10">最近30天</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype2 == 20">最近12月</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype2 == 30">最近10年</span>
                    <el-dropdown @command="staticUser">
                        <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }">
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command=10>最近30天</el-dropdown-item>
                            <el-dropdown-item command=20>最近12月</el-dropdown-item>
                            <el-dropdown-item command=30>最近10年</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="add-content">
                <div id="user-echarts"/>
            </div>

            <!--平台API访问数-->
            <div class="module-header">
                <div class="title">平台API访问数</div>
                <div class="header-region">
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype3 == 10">最近30天</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype3 == 20">最近12月</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype3 == 30">最近10年</span>
                    <el-dropdown @command="staticApi">
                        <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }">
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command=10>最近30天</el-dropdown-item>
                            <el-dropdown-item command=20>最近12月</el-dropdown-item>
                            <el-dropdown-item command=30>最近10年</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="add-content">
                <div id="api-echarts"/>
            </div>

            <!--平台接入设备数-->
            <div class="module-header">
                <div class="title">平台接入设备数</div>
                <div class="header-region">
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype4 == 10">最近30天</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype4 == 20">最近12月</span>
                    <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }" v-if="stype4 == 30">最近10年</span>
                    <el-dropdown @command="staticDevice">
                        <span class="el-dropdown-link" :style="{ color: ORIGINAL_THEME }">
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command=10>最近30天</el-dropdown-item>
                            <el-dropdown-item command=20>最近12月</el-dropdown-item>
                            <el-dropdown-item command=30>最近10年</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <div class="add-content">
                <div id="device-echarts"/>
            </div>

        </div>

    </div>
  </template>
  
  <script>
  import DiluTable from "@/components/public/dilu-table.vue";
  import * as echarts from "echarts";
  import { mapState } from "vuex";
  export default {
    data() {
      return {
        stype1: 10,
        stype2: 10,
        stype3: 10,
        stype4: 10,
        summaryData: [],
        summaryConfig: {
            isPage: false
        },
      };
    },
    mounted() {
      this.staticSummary();
      this.staticGateway(10);
      this.staticUser(10);
      this.staticApi(10);
      this.staticDevice(10);
    },
    computed: {
      ...mapState(["ORIGINAL_THEME"]),
    },
    components: {
      DiluTable,
    },
    methods: {
      //汇总信息数据
      staticSummary() {
        var params={};
        this.request.staticSummary(params).then((res) => {
          if (res.data!=undefined&&res.data!=null) {
            this.summaryData=[res.data];
            console.log("summaryData",this.summaryData);
          }
        });
      },
      //通讯网关活跃数
      staticGateway(stype) {
        this.stype1=stype;
        var params={"stype":stype};
        this.request.staticGateway(params).then((res) => {
          if (res.data!=undefined&&res.data!=null) {
            let x = [];
            let y = [];
            let counts=res.data.length;
            for (let i=counts-1; i>=0; i--) {
                x.push(res.data[i].item);
                y.push(res.data[i].data);
            }
            this.initGatewayEcharts(x, y);
          }
        });
      },
      initGatewayEcharts(x, y) {
        var chartDom = document.getElementById("gateway-echarts");
        var myChart = echarts.init(chartDom,'light');
        var option;
        let date = x;
        let data = y;
        option = {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: 80,
            right: 30,
            top: 50,
            bottom: 50,
          },
          series: [
            {
              data: data,
              areaStyle: {},
              type: "line",
              label: {
                show: false,
              },
            },
          ],
        };
        option && myChart.setOption(option);
      },
      //APP用户活跃数
      staticUser(stype) {
        this.stype2=stype;
        var params={"stype":stype};
        this.request.staticUser(params).then((res) => {
          if (res.data!=undefined&&res.data!=null) {
            let x = [];
            let y = [];
            let counts=res.data.length;
            for (let i=counts-1; i>=0; i--) {
                x.push(res.data[i].item);
                y.push(res.data[i].data);
            }
            this.initUserEcharts(x, y);
          }
        });
      },
      initUserEcharts(x, y) {
        var chartDom = document.getElementById("user-echarts");
        var myChart = echarts.init(chartDom,'light');
        var option;
        let date = x;
        let data = y;
        option = {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: 80,
            right: 30,
            top: 50,
            bottom: 50,
          },
          series: [
            {
              data: data,
              areaStyle: {},
              type: "line",
              label: {
                show: false,
              },
            },
          ],
        };
        option && myChart.setOption(option);
      },
      //平台API访问数
      staticApi(stype) {
        this.stype3=stype;
        var params={"stype":stype};
        this.request.staticApi(params).then((res) => {
          if (res.data!=undefined&&res.data!=null) {
            let x = [];
            let y = [];
            let counts=res.data.length;
            for (let i=counts-1; i>=0; i--) {
                x.push(res.data[i].item);
                y.push(res.data[i].data);
            }
            this.initApiEcharts(x, y);
          }
        });
      },
      initApiEcharts(x, y) {
        var chartDom = document.getElementById("api-echarts");
        var myChart = echarts.init(chartDom,'light');
        var option;
        let date = x;
        let data = y;
        option = {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: 80,
            right: 30,
            top: 50,
            bottom: 50,
          },
          series: [
            {
              data: data,
              areaStyle: {},
              type: "line",
              label: {
                show: false,
              },
            },
          ],
        };
        option && myChart.setOption(option);
      },
      //平台接入设备数
      staticDevice(stype) {
        this.stype4=stype;
        var params={"stype":stype};
        this.request.staticDevice(params).then((res) => {
          if (res.data!=undefined&&res.data!=null) {
            let x = [];
            let y = [];
            let counts=res.data.length;
            for (let i=counts-1; i>=0; i--) {
                x.push(res.data[i].item);
                y.push(res.data[i].data);
            }
            this.initDeviceEcharts(x, y);
          }
        });
      },
      initDeviceEcharts(x, y) {
        var chartDom = document.getElementById("device-echarts");
        var myChart = echarts.init(chartDom,'light');
        var option;
        let date = x;
        let data = y;
        option = {
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: true,
            },
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: 80,
            right: 30,
            top: 50,
            bottom: 50,
          },
          series: [
            {
              data: data,
              areaStyle: {},
              type: "line",
              label: {
                show: false,
              },
            },
          ],
        };
        option && myChart.setOption(option);
      },
    },
  };
  </script>
  
  <style scoped>
  .home {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
  }
  .summary {
    margin-left:20px;
    margin-right:20px;
    margin-top:40px;
  }
  .module-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .module-header .title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .header-region {
    background: rgba(210, 222, 244, 0.35);
    border-radius: 15px;
    padding: 5px 20px;
    box-sizing: border-box;
  }
  #gateway-echarts {
    width: 100%;
    height: 400px;
  }
  #user-echarts {
    width: 100%;
    height: 400px;
  }
  #api-echarts {
    width: 100%;
    height: 400px;
  }
  #device-echarts {
    width: 100%;
    height: 400px;
  }
  .add-content {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  </style>